@import "app/webapp-common/common-styles";

sm-tasks-table .card, sm-models-view .card, sm-task-info .card, sm-models .card {
  border: none;
}

.text-center .generic-container .buttons {
  display: block !important;
}

.i-welcome-researcher {
  background-image: url('assets/welcome-researcher.svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  display: inline-block;
}
